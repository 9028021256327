import React, { Component } from 'react';
import { BaseRequestPage } from './BaseRequestPage';
import APIClient from '../../services/APIClient';
import qs from 'qs';

export class RejectRequestPage extends Component<any, any> {
  action = () => {
    const {
      match: {
        params: { slug },
      },
      location: { search },
    } = this.props;

    const query = qs.parse(search, { ignoreQueryPrefix: true });
    const comment = query.comment;

    return APIClient.requestReject({ slug }, comment ? { comment } : {});
  };

  render() {
    return <BaseRequestPage action={this.action} />;
  }
}
